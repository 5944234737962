import PropTypes from 'prop-types';
import Container from 'components/atoms/Container';

const EmptyTemplate = ({ children }) => (
  <Container>
    {children}
    {process.env.NODE_ENV === 'production' && (
      <noscript>
        <div>
          <img
            src="https://mc.yandex.ru/watch/28073193"
            style={{ position: 'absolute', left: -9999 }}
            alt=""
          />
        </div>
      </noscript>
    )}
  </Container>
);

EmptyTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EmptyTemplate;
