import { Component } from 'react';
import styled from 'styled-components';
import EmptyTemplate from 'components/templates/EmptyTemplate';
import Logo from 'components/atoms/Logo';


const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
`;

const Text = styled.p`
  text-align: center;
  font-style: italic;
  margin: 3em 0;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  width: 300px;
  max-width: 100%;
`;

const Header = styled.header`
  width: 100%;
  padding: 1em 0;
  border-bottom: 0.3em solid ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
`;

class SubscriptionPage extends Component {
  render() {
    return (
      <EmptyTemplate>
        <Wrapper>
          <Header>
            <StyledLogo />
          </Header>
          <Title>Полезные новости</Title>
          <Text>Скидки, информация о поступлении товара, розыгрыши призов в соцсетях — узнайте первым! Подпишитесь!</Text>
          <form action='https://ru.msndr.net/subscriptions/7556a038818f7c91265acd86f573e67a/form' charset='UTF-8' method='post'>
            <input type="hidden" name="recipient[recipient_values_attributes][242928][recipient_parameter_id]" id="recipient_recipient_values_attributes_242928_recipient_parameter_id" value="242928" />
            <div>
              <label for="recipient_recipient_values_attributes_242928_value">Ваше имя</label>
              <input type="text" name="recipient[recipient_values_attributes][242928][value]" id="recipient_recipient_values_attributes_242928_value" required="required" />
            </div>
            <div>
              <label for="email">Email</label>
              <input type="text" name="recipient[email]" id="recipient_email" value="" required="required" />
            </div>
            <button type='submit'>Подписаться</button>
          </form>
        </Wrapper>
      </EmptyTemplate>
    );
  }
}

export default SubscriptionPage;
